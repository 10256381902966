import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from "styled-components"
import Button from "../components/button"

const Container = Styled.div`
  margin-top: 86px;
  h3 {
    color: white;
    margin-bottom: 30px;
  }

  @media all and (min-width: 992px) {
    margin-top: 60px;
    padding: 50px 75px;
  }
}
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>PAGE NOT FOUND</h1>
      <h3>You just hit a route that doesn&#39;t exist... the sadness.</h3>
      <Button to={"/"}>
        Go to home page
      </Button>
    </Container>
  </Layout>
)

export default NotFoundPage
